<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">MONTHLY EVALUATION EMPLOYEES</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5 mb-5">
        <v-flex xs12 md3>
          <v-select
            v-model="month_of_id"
            class="mx-2"
            dense
            outlined
            label="Month Of"
            :items="month_of_items"
            item-value="id"
            item-text="month_of"
            @change="selected_category"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3>
          <v-select
            v-model="category_id"
            class="mx-2"
            dense
            outlined
            label="Category"
            :items="category_items"
            item-value="id"
            item-text="category"
            @change="selected_category"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3 v-if="is_bod">
          <v-select
            v-model="employee_position_id"
            class="mx-2"
            dense
            outlined
            label="Position"
            :items="employee_position_items"
            item-value="id"
            item-text="position"
            @change="selected_data"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3 v-if="is_bod">
          <v-select
            v-model="selected_branch_id"
            class="mx-2"
            dense
            outlined
            label="Branch/Department"
            :items="employee_branch_items"
            item-value="id"
            item-text="branch_code"
            @change="selected_data"
          ></v-select>
        </v-flex>

        <v-flex xs12 md12 v-if="is_generated">
          <v-select
            class="mx-2"
            v-model="bank"
            dense
            outlined
            label="Bank"
            :items="bank_items"
            item-text="bank_type"
            item-value="bank_type"
            :rules="rules.combobox_rule"
            @change="selected_bank_data"
          ></v-select>
        </v-flex>
        <v-col cols="12" v-show="alert">
          <v-alert
            color="warning"
            text
            class="mb-0"
          >
            <div class="d-flex align-start">
              <v-icon color="warning">
                {{ icons.mdiAlertOutline }}
              </v-icon>

              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{ alert_message }}
                </p>
              </div>
            </div>
          </v-alert>
        </v-col>
        <v-col cols="12" md="12"
               v-if="is_bod && !is_generated && (final_personal_info.length>0||final_department_personal_info.length>0)">
          <v-btn
            class="w-full"
            color="success"
            @click="approved_data"
            v-if="!saving_data"
          >
            Approved
          </v-btn>
          <v-progress-circular
            :size=50
            :width="5"
            color="primary"
            indeterminate
            v-else
          ></v-progress-circular>
        </v-col>
        <v-col cols="12" md="12" v-if="is_bod && is_generated">
          <v-btn
            class="w-full"
            color="info"
            @click="print_data"
            v-if="!is_printing"
          >
            Print Data
          </v-btn>
          <v-progress-circular
            :size=50
            :width="5"
            color="primary"
            indeterminate
            v-else
          ></v-progress-circular>
        </v-col>
      </v-layout>

      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">HEADS</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-data-table dense
                    class="mt-3"
                    :headers="is_bod?heads_headers:headers"
                    :items="final_personal_info"
                    disable-pagination
                    hide-default-footer
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{
                (final_personal_info
                  .map(function (x) {
                    return x.id
                  })
                  .indexOf(item.id) + 1)
              }}
            </td>
            <td>
              <div v-if="(!is_generated)">
                <div v-if="item.evaluation_points === 0">
                  <v-icon
                    v-if="((employee_id!=item.id) || (employee_id===item.id&&(is_csr||is_gad)))"
                    color="info"
                    @click="change_stat_cash_advance(item,is_bod)"
                  >{{ icons.mdiBadgeAccountAlertOutline }}
                  </v-icon>
                </div>
                <div v-else>
                  {{ item.evaluation_points + ' points' }}
                </div>
                <v-icon
                  v-if="(is_head && is_same_branch_checker(item.branch_id))||is_bod"
                  color="success"
                  @click="view_stat_cash_advance(item,1)"
                >{{ icons.mdiEyeCircle }}
                </v-icon>
              </div>
            </td>
            <td v-if="is_bod||is_head||is_csr||is_gad">
              <div>
                <v-chip color="error" v-if="!status">
                  NOT YET DONE
                </v-chip>
                <v-chip color="success" v-else>
                  DONE
                </v-chip>
              </div>
            </td>
            <td v-if="!is_generated">
              {{ item.name }}
            </td>
            <td v-else>
              {{
                item.employee.last_name + ', '
                + item.employee.first_name
                + ' ' + item.employee.middle_name
              }}
            </td>
            <td>
              {{ item.branch.branch_code }}
            </td>
            <td>
              {{ item.employee_position.position }}
            </td>
            <td>
              {{ item.duration }}
            </td>
            <td
              v-if="(is_bod)">
              {{ item.employee_percentage > 0 ? item.employee_percentage + ' %' : '' }}
            </td>
            <td
              v-if="(is_bod)">
              {{ item.head_percentage > 0 ? item.head_percentage + ' %' : '' }}
            </td>
            <td
              v-if="(is_bod)">
              {{ item.csr_and_gad_percentage > 0 ? item.csr_and_gad_percentage + ' %' : '' }}
            </td>
            <td
              v-if="(is_bod)">
              {{ item.bod_percentage > 0 ? item.bod_percentage + ' %' : '' }}
            </td>
            <td
              v-if="(is_bod||(employee_id===item.id))">
              {{ item.total_percentage > 0 ? item.total_percentage + ' %' : '' }}
            </td>
            <td
              v-if="(is_bod||(employee_id===item.id))">
              {{ item.incentives > 0 ? formatPrice(item.incentives) : '' }}
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-toolbar dense dark color="primary" class="mt-10">
        <v-toolbar-title><h4 class="font-weight-light">DEPARMENTS</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-data-table dense
                    class="mt-3"
                    :headers="(is_bod||is_head)?department_headers:headers"
                    :items="final_department_personal_info"
                    disable-pagination
                    hide-default-footer
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{
                (final_department_personal_info
                  .map(function (x) {
                    return x.id
                  })
                  .indexOf(item.id) + 1)
              }}
            </td>
            <td>
              <div v-if="(!is_generated)">
                <div v-if="item.evaluation_points === 0">
                  <v-icon
                    v-if="((employee_id!=item.id) || (employee_id===item.id&&(is_csr||is_gad)))"
                    color="info"
                    @click="change_stat_cash_advance(item,is_bod)"
                  >{{ icons.mdiBadgeAccountAlertOutline }}
                  </v-icon>
                </div>
                <div v-else>
                  {{ item.evaluation_points + ' points' }}
                </div>
                <v-icon
                  v-if="(is_head && is_same_branch_checker(item.branch_id))||is_bod"
                  color="success"
                  @click="view_stat_cash_advance(item,1)"
                >{{ icons.mdiEyeCircle }}
                </v-icon>
              </div>
            </td>
            <td v-if="is_bod||is_head||is_csr||is_gad">
              <div>
                <v-chip color="error" v-if="!status">
                  NOT YET DONE
                </v-chip>
                <v-chip color="success" v-else>
                  DONE
                </v-chip>
              </div>
            </td>
            <td v-if="!is_generated">
              {{ item.name }}
            </td>
            <td v-else>
              {{
                item.employee.last_name + ', '
                + item.employee.first_name
                + ' ' + item.employee.middle_name
              }}
            </td>
            <td>
              {{ item.branch.branch_code }}
            </td>
            <td>
              {{ item.employee_position.position }}
            </td>
            <td>
              {{ item.duration }}
            </td>
            <td
              v-if="(is_bod||is_head)">
              {{ item.employee_percentage > 0 ? item.employee_percentage + ' %' : '' }}
            </td>
            <td
              v-if="(is_bod||is_head)">
              {{ item.head_percentage > 0 ? item.head_percentage + ' %' : '' }}
            </td>
            <td
              v-if="(is_bod||is_head)">
              {{ item.csr_and_gad_percentage > 0 ? item.csr_and_gad_percentage + ' %' : '' }}
            </td>
            <td
              v-if="(is_bod||is_head)">
              {{ item.bod_percentage > 0 ? item.bod_percentage + ' %' : '' }}
            </td>
            <td
              v-if="(is_bod||is_head||(employee_id===item.id))">
              {{ item.total_percentage > 0 ? item.total_percentage + ' %' : '' }}
            </td>
            <td
              v-if="(is_bod||(employee_id===item.id))">
              {{ item.incentives > 0 ? formatPrice(item.incentives) : '' }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="is_change_salary" persistent max-width="70%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">PEER EVALUATION FORM</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-card class="w-full" v-if="(!is_gad&&!is_csr)||(is_same_branch)">
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">COMMUNICATIONS</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-flex xs12 md12>
              <v-radio-group
                class="mx-4"
                v-for="commu in communications"
                mandatory
                v-model="commu.model"
                row

              >{{ commu.label }}
                <v-spacer></v-spacer>
                <v-radio v-for="row in answer_row"
                         :key="row.value"
                         :label="row.label"
                         :value="row.value"
                ></v-radio>
              </v-radio-group>
            </v-flex>
          </v-card>
          <v-card class="w-full" v-if="(!is_gad&&!is_csr)||(is_same_branch)">
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">ATTENDANCE AND PUNCTUALITY</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-flex xs12 md12>
              <v-radio-group
                class="mx-2"
                v-for="commu in attendances"
                mandatory
                v-model="commu.model"
                row

              >{{ commu.label }}
                <v-spacer></v-spacer>
                <v-radio v-for="row in answer_row"
                         :key="row.value"
                         :label="row.label"
                         :value="row.value"
                ></v-radio>
              </v-radio-group>
            </v-flex>
          </v-card>
          <v-card class="w-full" v-if="(!is_gad&&!is_csr)||(is_same_branch)">
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">PRODUCTIVITY AND RESILIENCE</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-flex xs12 md12>
              <v-radio-group
                class="mx-2"
                v-for="commu in productivities"
                mandatory
                v-model="commu.model"
                row

              >{{ commu.label }}
                <v-spacer></v-spacer>
                <v-radio v-for="row in answer_row"
                         :key="row.value"
                         :label="row.label"
                         :value="row.value"
                ></v-radio>
              </v-radio-group>
            </v-flex>
          </v-card>
          <v-card class="w-full" v-if="(!is_gad&&!is_csr)||(is_same_branch)">
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">INIATIVE/PROACTIVITY</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-flex xs12 md12>
              <v-radio-group
                class="mx-2"
                v-for="commu in initiatives"
                mandatory
                v-model="commu.model"
                row

              >{{ commu.label }}
                <v-spacer></v-spacer>
                <v-radio v-for="row in answer_row"
                         :key="row.value"
                         :label="row.label"
                         :value="row.value"
                ></v-radio>
              </v-radio-group>
            </v-flex>
          </v-card>
          <v-card class="w-full" v-if="(!is_gad&&!is_csr)||(is_same_branch)">
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">BEHAVIOR</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-flex xs12 md12>
              <v-radio-group
                class="mx-2"
                v-for="commu in behaviors"
                mandatory
                v-model="commu.model"
                row

              >{{ commu.label }}
                <v-spacer></v-spacer>
                <v-radio v-for="row in answer_row"
                         :key="row.value"
                         :label="row.label"
                         :value="row.value"
                ></v-radio>
              </v-radio-group>
            </v-flex>
          </v-card>
          <v-card class="w-full" v-if="is_csr||is_gad">
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">CORPORATE SOCIAL RESPONSIBILITY(CSR)
                AND GENDER AND DEVELOPMENT(GAD) PARTICIPATION</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-flex xs12 md12>
              <v-radio-group
                class="mx-2"
                v-for="commu in is_csr?csr_ques:(is_gad?gad_ques:[])"
                mandatory
                v-model="commu.model"
                row

              >{{ commu.label }}
                <v-spacer></v-spacer>
                <v-radio v-for="row in answer_row"
                         :key="row.value"
                         :label="row.label"
                         :value="row.value"
                ></v-radio>
              </v-radio-group>
            </v-flex>
          </v-card>
          <v-flex xs12 md12>
            <v-textarea
              class="mx-2 mt-3"
              v-model="comment"
              dense
              label="Comment"
              outlined
              :rules="rules.default_max_500_character_rule"
            ></v-textarea>
          </v-flex>
        </v-layout>
        <v-btn class="mt-4 w-full" color="primary" @click="update_cash_advance_previlige"
               v-if="!saving"> Save
        </v-btn>
        <v-progress-circular
          :size=50
          :width="5"
          color="primary"
          indeterminate
          v-else
        ></v-progress-circular>
        <v-btn class="mt-4 w-full" color="error" @click="is_change_salary = false"> Close</v-btn>
      </v-card>
    </v-dialog>
    <v-dialog v-model="is_bod_change_points" persistent max-width="80%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">PEER EVALUATION FORM</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="new_cfo_points"
              label="New CFO Points"
              dense
              outlined
              min="0"
              max="25"
              type="number"
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-btn class="mt-4 w-full" color="primary" @click="update_cash_advance_previlige"
               v-if="!saving"> Save
        </v-btn>
        <v-progress-circular
          :size=50
          :width="5"
          color="primary"
          indeterminate
          v-else
        ></v-progress-circular>
        <v-btn class="mt-4 w-full" color="error" @click="is_bod_change_points = false"> Close
        </v-btn>
      </v-card>
    </v-dialog>
    <v-dialog v-model="is_view_points" persistent max-width="80%">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">VIEW POINTS & COMMENTS</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-data-table dense
                      class="mt-3"
                      :headers="points_header"
                      :items="points_items"
                      disable-pagination
                      hide-default-footer
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>
                {{ item.particulars }}
              </td>
              <td>
                {{ item.value }}
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-toolbar dense>
          <v-toolbar-title><h4 class="font-weight-light">COMMENTS AREA</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-data-table dense
                      class="mt-3"
                      :items="comments_items"
                      disable-pagination
                      hide-default-footer
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>
                {{
                  comments_items
                    .map(function (x) {
                      return x.comments
                    })
                    .indexOf(item.comments) + 1
                }}
              </td>
              <td>
                {{ item.comments }}
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-btn class="mt-4 w-full" color="error" @click="is_view_points = false"> Close
        </v-btn>
      </v-card>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
import {
  mdiAccountOutline, mdiAccountCashOutline
  , mdiInformationOutline
  , mdiBadgeAccountAlertOutline
  , mdiPrinter
  , mdiEyeCircle
} from "@mdi/js";
import moment from "moment";
import {mapActions, mapGetters} from "vuex";
import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

const initialState = () => {
  return {
    alert: false,
    alert_message: '',

    new_cfo_points: '0',
    is_view_points: false,
    is_change_salary: false,
    is_same_branch: false,
    is_generated: false,
    is_bod_change_points: false,
    saving_data: false,
    is_printing: false,
    saving: false,
    is_head: false,
    is_bod: false,
    is_csr: false,
    is_gad: false,
    category_id: '',
    new_incentives: '0',
    employee_position_id: 0,
    month_of_id: '',
    comment: '',
    category_items: [],
    month_of_items: [],
    category_items2: [],
    selected_branch_id: '',
    employee_branch_items: [],
    comments_items: [],
    personal_info: [],
    final_personal_info: [],
    final_department_personal_info: [],
    bank_info: [],
    employment_info: [],
    type_of_employee: [],
    employee_position_items: [],
    points_items: [],
    bank_items: [],
    bank_items2: [],
    all_items: [],
    sorted_by: 'Name',
    bank: '',
    status: 'All',
    duration: 'All',
    maximum_amount: '',
    eval_employee_id: '',
    communications: [
      {model: '1', label: 'Actively listens to supervisors or co-workers.'},
      {model: '1', label: 'Consistently delivers accurate information both written and oral.'},
      {model: '1', label: 'Comprehends written and oral information.'},
      {model: '1', label: 'Reliable provides feedback as required both internally and externally.'},
    ],
    attendances: [
      {model: '1', label: 'Punctual on regular basis.'},
      {model: '1', label: 'Comes to work place regularly.'},
      {model: '1', label: 'Informs supervisor when absent or late.'},
      {model: '1', label: 'Has an excellent attendance record.'},
    ],
    productivities: [
      {model: '1', label: 'Consistently produces quality results.'},
      {model: '1', label: 'Meets deadlines and manages time well.'},
      {model: '1', label: 'Can do multi-tasking.'},
      {model: '1', label: 'Can work under pressure and delivers the required task.'},
      {model: '1', label: 'Effective and efficient in the management.'},
      {
        model: '1',
        label: 'Informs supervisor of any challenge or hindrance met related to given task or arrangement.'
      },
    ],
    initiatives: [
      {model: '1', label: 'Completes assignments with minimum supervision.'},
      {model: '1', label: 'Completes task independently and consistently.'},
      {model: '1', label: 'Seeks support as the need arises.'},
      {model: '1', label: 'Recognize and takes immediate action to effectively address problems.'},
      {model: '1', label: 'Engages and continuous learning.'},
      {model: '1', label: 'Contributes new ideas and shares skills to improve the branch.'},
    ],
    behaviors: [
      {model: '1', label: 'Willing to answer call anytime.'},
      {model: '1', label: 'Works through conflict for positive solutions/results.'},
      {model: '1', label: 'Keeps appointments, call-return commitments, etc.'},
      {
        model: '1',
        label: 'Demonstrates courtesy and a professional attitude in handling customer/agent/supervisors complaints.'
      },
      {
        model: '1',
        label: 'Adapts well to new situations, unusual demands, emergencies, or critical incidents.'
      },
    ],
    csr_ques: [
      {
        model: '1',
        label: 'Actively participates in company-led CSR initiatives (e.g.,outreach programs,environmental projects,community service).'
      },
      {model: '1', label: 'Engages in volunteer services for community development.'},
      {model: '1', label: 'Donates or contributes to CSR programs.'},
    ],
    gad_ques: [
      {
        model: '1',
        label: 'Participates in Gender and Development (GAD) activites promoting inclusivity and equality.'
      },
      {model: '1', label: 'Supports initiatives that empower women and marginalized groups.'},
    ],
    answer_row: [
      {label: '1', value: '1'},
      {label: '2', value: '2'},
      {label: '3', value: '3'},
      {label: '4', value: '4'},
      {label: '5', value: '5'},
    ],
    points_header: [
      {text: 'Particulars', value: 'particulars', sortable: true},
      {text: 'Value', value: 'value', sortable: true},
    ],
    headers: [
      {text: 'No.', value: 'date_of_deposit', sortable: false},
      {text: 'Evaluate', value: 'last_name', sortable: false},
      {text: 'Status', value: 'last_name', sortable: false},
      {text: 'Name', value: 'last_name', sortable: false},
      {text: 'Branch/Department', value: 'last_name', sortable: false},
      {text: 'Designation', value: 'last_name', sortable: false},
      {text: 'Duration', value: 'last_name', sortable: false},
      {text: 'Total Points (100%)', value: 'total_percentage', sortable: true},
      {text: 'Incentives', value: 'incentives', sortable: true},
    ],
    heads_headers: [
      {text: 'No.', value: 'date_of_deposit', sortable: false},
      {text: 'Evaluate', value: 'last_name', sortable: false},
      {text: 'Status', value: 'last_name', sortable: false},
      {text: 'Name', value: 'name', sortable: true},
      {text: 'Branch/Department', value: 'branch.branch_code', sortable: true},
      {text: 'Designation', value: 'employee_position.position', sortable: true},
      {text: 'Duration', value: 'last_name', sortable: true},
      {text: 'Subordinates(40%)', value: 'employee_percentage', sortable: true},
      {text: 'Heads(20%)', value: 'head_percentage', sortable: true},
      {text: 'CSR/GAD(20%)', value: 'head_percentage', sortable: true},
      {text: 'BOD(20%)', value: 'bod_percentage', sortable: true},
      {text: 'Total Points (100%)', value: 'total_percentage', sortable: true},
      {text: 'Incentives', value: 'incentives', sortable: true},
    ],
    department_headers: [
      {text: 'No.', value: 'date_of_deposit', sortable: false},
      {text: 'Evaluate', value: 'last_name', sortable: false},
      {text: 'Status', value: 'last_name', sortable: false},
      {text: 'Name', value: 'name', sortable: true},
      {text: 'Branch/Department', value: 'branch.branch_code', sortable: true},
      {text: 'Designation', value: 'employee_position.position', sortable: true},
      {text: 'Duration', value: 'last_name', sortable: true},
      {text: 'Department(30%)', value: 'department_percentage', sortable: true},
      {text: 'Heads(30%)', value: 'head_percentage', sortable: true},
      {text: 'CSR/GAD(30%)', value: 'head_percentage', sortable: true},
      {text: 'BOD(10%)', value: 'bod_percentage', sortable: true},
      {text: 'Total Points (100%)', value: 'total_percentage', sortable: true},
      {text: 'Incentives', value: 'incentives', sortable: true},
    ],
  }
}
export default {
  components: {
    snackBarDialog,
  },
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiAccountCashOutline,
        mdiInformationOutline,
        mdiBadgeAccountAlertOutline,
        mdiEyeCircle,
        mdiPrinter,
      },
      is_deleting: false,
    }
  },
  data() {
    return initialState()
  },
  mounted() {
    this.is_bod = this.employee_id === 2 || this.employee_id === 1 || this.employee_id === 3
    if (!this.is_can_evaluate) {
      this.$router.push({path: '/error-404'})
    }
    this.initialize_data()
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('authentication', ['position', 'is_can_evaluate']),
    ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'damayan', 'chapel', 'factory', 'coop', 'printing']),
    ...mapGetters('authentication', ['position', 'id_no', 'employee_category_id', 'employee_id', 'employee_branch_id']),
    ...mapGetters('authentication', ['address', 'contact', 'name', 'department', 'position']),
  },
  methods: {
    ...mapActions('system_data', ['change_snackbar']),
    ...mapActions('employee_evaluation', ['evaluate_employee', 'list_of_employee_to_evaluate', 'list_of_employee_to_evaluate_in_department', 'evaluate_below_employee']),
    ...mapActions('employee', ['initialize_list_of_employee_for_evaulation', 'is_head_checker_employee', 'is_csr_gad_checker_employee']),
    ...mapActions('employee_evaluation_status', ['create_employee_is_done_evaluate']),
    ...mapActions('employee_evaluation_data', ['create_employee_evaluation_data']),
    is_same_branch_checker(value) {
      return (this.employee_branch_id === value)
    },
    is_head_checker(value) {
      this.is_head_checker_employee({
        employee_id: value
      })
        .then(response => {
          this.is_head = response.data;
        })
      this.is_csr_gad_checker_employee({
        employee_id: value
      })
        .then(response => {
          this.is_csr = response.data[0].is_csr;
          this.is_gad = response.data[0].is_gad;
        })
    },
    async initialize_data() {
      await this.is_head_checker(this.employee_id)
      await this.initialize_list_of_employee_for_evaulation()
        .then(response => {
          this.category_items2 = response.data[0].category
          this.month_of_items = response.data[0].months
        })
        .catch(error => {
          console.log(error)
        })
      if (this.employee_category_id === 1) {
        this.category_items.push(this.category_items2[
          this.category_items2
            .map(function (x) {
              return x.id
            })
            .indexOf(2)
          ])
      } else {
        this.category_items.push(this.category_items2[
          this.category_items2
            .map(function (x) {
              return x.id
            })
            .indexOf(this.employee_category_id)
          ])
      }
    },
    duration_counter(value) {
      var a = moment(this.month_of_items[this.month_of_items.map(function (x) {
        return x.id;
      }).indexOf(this.month_of_id)].month_of, 'MMMM YYYY');
      var b = moment(value, 'YYYY-MM-DD');

      var years = a.diff(b, 'year');
      b.add(years, 'years');

      var months = a.diff(b, 'months');
      b.add(months, 'months');

      var days = a.diff(b, 'days');
      return years + ' years ' + months + ' months ' + days + ' days'
    },
    date_format(value) {
      return moment(value)
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    change_stat_cash_advance(value, is_bod) {
      this.eval_employee_id = value.id
      if (is_bod) {
        // this.new_cfo_points = value.evaluation.length > 0 ? value.evaluation[0].total + '' : '0';
        // this.is_bod_change_points = true
        this.is_change_salary = true
      } else {
        this.is_same_branch = this.is_same_branch_checker(value.branch_id)
        this.is_change_salary = true
      }
    },
    async view_stat_cash_advance(value) {
      this.points_items = []
      var q1 = 0;
      var q2 = 0;
      var q3 = 0;
      var q4 = 0;
      var q5 = 0;
      var dat = [];
      if (value.over_all_evaluation.length > 0) {
        await value.over_all_evaluation.forEach(function (item) {
          q1 += item.q1;
          q2 += item.q2;
          q3 += item.q3;
          q4 += item.q4;
          q5 += item.q5;

          if (item.comment != null) {
            dat.push({
              comments: item.comment,
            })
          }
        })
      }
      this.comments_items = dat

      this.points_items.push({
        particulars: 'Initiative', value: q1,
      })
      this.points_items.push({
        particulars: 'Punctuality', value: q2,
      })
      this.points_items.push({
        particulars: 'Collaborative', value: q3,
      })
      this.points_items.push({
        particulars: 'Professionalism', value: q4,
      })
      this.points_items.push({
        particulars: 'Interpersonal Communication', value: q5,
      })

      this.is_view_points = true
    },
    update_cash_advance_previlige() {
      this.saving = true
      var proceed = true;
      if (this.is_bod) {
        if (this.new_cfo_points > 25) {
          this.alert = true
          this.alert_message = 'Points not greater then 25'
          this.saving_data = false
          this.saving = false
          this.is_bod_change_points = false
          proceed = false
        }
      }
      if (proceed) {
        this.alert = false
        this.alert_message = ''
        this.saving_data = false
        this.saving = false
        this.evaluate_employee({
          month_of_id: this.month_of_id,
          created_by: this.employee_id,
          employee_id: this.eval_employee_id,
          comment: this.comment.toUpperCase(),
          is_bod: this.is_bod ? 1 : 0,
          is_head: this.is_bod ? 0 : (this.is_head ? 1 : 0),
          is_subordinate: this.is_bod ? 0 : (this.is_head ? 0 : 1),
          is_csr: this.is_csr ? 1 : 0,
          is_gad: this.is_gad ? 1 : 0,

          q1: this.communications[0].model,
          q2: this.communications[1].model,
          q3: this.communications[2].model,
          q4: this.communications[3].model,

          q5: this.attendances[0].model,
          q6: this.attendances[1].model,
          q7: this.attendances[2].model,
          q8: this.attendances[3].model,

          q9: this.productivities[0].model,
          q10: this.productivities[1].model,
          q11: this.productivities[2].model,
          q12: this.productivities[3].model,
          q13: this.productivities[4].model,
          q14: this.productivities[5].model,

          q15: this.initiatives[0].model,
          q16: this.initiatives[1].model,
          q17: this.initiatives[2].model,
          q18: this.initiatives[3].model,
          q19: this.initiatives[4].model,
          q20: this.initiatives[5].model,

          q21: this.behaviors[0].model,
          q22: this.behaviors[1].model,
          q23: this.behaviors[2].model,
          q24: this.behaviors[3].model,
          q25: this.behaviors[4].model,

          q26: this.csr_ques[0].model,
          q27: this.csr_ques[1].model,
          q28: this.csr_ques[2].model,
          q29: this.gad_ques[0].model,
          q30: this.gad_ques[1].model,
        }).then(response => {
          this.alert = true
          this.alert_message = response.data
          this.saving_data = false
          this.saving = false
          this.selected_data()
        })
          .catch(error => {
            console.log(error)
          })
      }
    },
    selected_category() {
      if (this.category_items.length > 0) {
        var index = this.category_items
          .map(function (x) {
            return x.id
          })
          .indexOf(this.category_id)
        if (index >= 0) {
          this.employee_position_items = this.category_items[index].employee_position
          this.employee_position_items.splice(0, 0, {
            id: 0,
            position: 'All'
          })
          this.employee_branch_items = this.category_items[index].branch
          this.employee_branch_items.splice(0, 0, {
            id: 0,
            branch_code: 'All'
          })
          this.selected_data()
        }
      }
    },
    selected_bank_data() {
      var index = this.bank_items
        .map(function (x) {
          return x.bank_type
        })
        .indexOf(this.bank)
      if (index >= 0) {
        this.final_personal_info = this.bank_items[index].detail
        this.final_department_personal_info = this.bank_items2[index].detail
      }
    },
    selected_data() {
      this.communications = [
        {model: '1', label: 'Actively listens to supervisors or co-workers.'},
        {model: '1', label: 'Consistently delivers accurate information both written and oral.'},
        {model: '1', label: 'Comprehends written and oral information.'},
        {
          model: '1',
          label: 'Reliable provides feedback as required both internally and externally.'
        },
      ]
      this.attendances = [
        {model: '1', label: 'Punctual on regular basis.'},
        {model: '1', label: 'Comes to work place regularly.'},
        {model: '1', label: 'Informs supervisor when absent or late.'},
        {model: '1', label: 'Has an excellent attendance record.'},
      ]
      this.productivities = [
        {model: '1', label: 'Consistently produces quality results.'},
        {model: '1', label: 'Meets deadlines and manages time well.'},
        {model: '1', label: 'Can do multi-tasking.'},
        {model: '1', label: 'Can work under pressure and delivers the required task.'},
        {model: '1', label: 'Effective and efficient in the management.'},
        {
          model: '1',
          label: 'Informs supervisor of any challenge or hindrance met related to given task or arrangement.'
        },
      ]
      this.initiatives = [
        {model: '1', label: 'Completes assignments with minimum supervision.'},
        {model: '1', label: 'Completes task independently and consistently.'},
        {model: '1', label: 'Seeks support as the need arises.'},
        {
          model: '1',
          label: 'Recognize and takes immediate action to effectively address problems.'
        },
        {model: '1', label: 'Engages and continuous learning.'},
        {model: '1', label: 'Contributes new ideas and shares skills to improve the branch.'},
      ],
        this.behaviors = [
          {model: '1', label: 'Willing to answer call anytime.'},
          {model: '1', label: 'Works through conflict for positive solutions/results.'},
          {model: '1', label: 'Keeps appointments, call-return commitments, etc.'},
          {
            model: '1',
            label: 'Demonstrates courtesy and a professional attitude in handling customer/agent/supervisors complaints.'
          },
          {
            model: '1',
            label: 'Adapts well to new situations, unusual demands, emergencies, or critical incidents.'
          },
        ]
      this.csr_and_gad = [
        {
          model: '1',
          label: 'Actively participates in company-led CSR initiatives (e.g.,outreach programs,environmental projects,community service).'
        },
        {model: '1', label: 'Engages in volunteer services for community development.'},
        {model: '1', label: 'Donates or contributes to CSR programs.'},
        {
          model: '1',
          label: 'Participates in Gender and Development (GAD) activites promoting inclusivity and equality.'
        },
        {model: '1', label: 'Supports initiatives that empower women and marginalized groups.'},
      ]
      if (!this.is_bod) {
        this.selected_branch_id = this.employee_branch_id
      }
      this.eval_employee_id = ''
      this.is_change_salary = false
      this.is_same_branch = false
      this.is_bod_change_points = false
      this.is_generated = false
      const data = new FormData()
      this.final_personal_info = []
      this.final_department_personal_info = []
      data.append('month_of_id', this.month_of_id);
      data.append('employee_id', this.employee_id);
      data.append('is_head', this.is_head);
      data.append('is_csr_and_gad', (this.is_csr || this.is_gad));
      data.append('is_bod', this.is_bod ? 1 : 0);
      data.append('created_by', this.employee_id);
      data.append('employee_position_id', this.employee_position_id);
      data.append('employee_branch_id', this.selected_branch_id);
      data.append('category_id', this.category_id);
      let total_points_each_employee = 125;
      let total_gad_points_each_employee = 25;
      this.list_of_employee_to_evaluate_in_department(data)
        .then(response => {
          if (response.data[0].details === 'generated') {
            this.is_generated = true
            this.bank_items2 = response.data[0].data
          } else {
            this.personal_info = response.data[0].data
            if (this.personal_info.length > 0) {
              var can_final = true
              var temp = []
              var over_all_points_rawr = 0
              var head_over_all_points_rawr = 0

              var ss_head = this.is_head;
              this.personal_info.forEach(function (item) {
                over_all_points_rawr = item.subordinate_evaluation.length * total_points_each_employee
                head_over_all_points_rawr = item.head_evaluation.length * total_points_each_employee
                var total_subordinate = 0
                item.subordinate_evaluation.forEach(function (item) {
                  if (item.is_csr_and_gad) {
                    total_subordinate += (parseFloat(item.total) - (parseFloat(item.q26) + parseFloat(item.q27) + parseFloat(item.q28) + parseFloat(item.q29) + parseFloat(item.q30)))
                  } else {
                    total_subordinate += parseFloat(item.total)
                  }
                })
                var total_head = 0
                item.head_evaluation.forEach(function (item) {
                  total_head += parseFloat(item.total)
                })
                var total_csr_gad = 0
                item.csr_and_gad_evaluation.forEach(function (item) {
                  if (item.is_subordinate) {
                    total_csr_gad += (parseFloat(item.q26) + parseFloat(item.q27) + parseFloat(item.q28) + parseFloat(item.q29) + parseFloat(item.q30))
                  } else {
                    total_csr_gad += parseFloat(item.total)
                  }
                })
                var employee_percentage = item.subordinate_evaluation.length > 0 ? (((total_subordinate.toFixed(2)
                  / (over_all_points_rawr)) * 30).toFixed(2)) : 0;
                var head_percentage = item.head_evaluation.length > 0 ?
                  (((parseFloat(total_head.toFixed(2)) / (head_over_all_points_rawr)) * 30).toFixed(2)) : 0;
                var csr_and_gad_percentage = item.csr_and_gad_evaluation.length > 0 ?
                  (((parseFloat(total_csr_gad.toFixed(2)) / (total_gad_points_each_employee)) * 30).toFixed(2)) : 0;
                var bod_points = item.bod_evaluation.length > 0 ? item.bod_evaluation[0].total : 0;
                var bod_percentage = item.bod_evaluation.length > 0 ? ((parseFloat(bod_points.toFixed(2)) / total_points_each_employee) * 10).toFixed(2) : 0;

                var total_percentage = (parseFloat(employee_percentage) + parseFloat(head_percentage) + parseFloat(csr_and_gad_percentage) + parseFloat(bod_percentage)).toFixed();
                var ince = 0
                if (total_percentage >= 96 && total_percentage <= 100) {
                  ince = 5000;
                } else if (total_percentage >= 91 && total_percentage <= 95) {
                  ince = 4000;
                } else if (total_percentage >= 86 && total_percentage <= 90) {
                  ince = 3500;
                } else if (total_percentage >= 81 && total_percentage <= 85) {
                  ince = 3000;
                } else if (total_percentage >= 76 && total_percentage <= 80) {
                  ince = 2000;
                } else if (total_percentage >= 71 && total_percentage <= 75) {
                  ince = 1500;
                } else if (total_percentage <= 70) {
                  ince = 0;
                }

                if (item.id === 188 || item.has_done_evaluate.length === 0) {//Laingo, Harold
                  ince = 0
                }
                var a = moment();
                var b = moment(item.date_of_employment, 'YYYY-MM-DD');

                var years = a.diff(b, 'year');
                b.add(years, 'years');

                var months = a.diff(b, 'months');
                b.add(months, 'months');

                var days = a.diff(b, 'days');

                temp.push({
                  id: item.id,
                  is_head: item.is_head,
                  has_done_evaluate: item.has_done_evaluate,
                  name: item.last_name + ','
                    + item.first_name + ' ' + item.middle_name,
                  branch: item.branch,
                  bank_type: item.bank_type_1,
                  bank_account_name: item.bank_account_1,
                  bank_account_no: item.bank_account_no_1,
                  employee_position: item.employee_position,
                  date_of_employment: item.date_of_employment,
                  over_all_evaluation: item.over_all_evaluation,
                  branch_id: item.branch_id,
                  evaluation_points: item.evaluation.length > 0 ? item.evaluation[0].total : 0,
                  status: Object.keys(item.evaluation).length > 0 ? 1 : 0,
                  csr_and_gad_points: total_csr_gad,
                  csr_and_gad_percentage: csr_and_gad_percentage,
                  subordinates_points: total_subordinate,
                  employee_percentage: employee_percentage,
                  head_points: total_head,
                  head_percentage: head_percentage,
                  bod_points: bod_points,
                  bod_percentage: bod_percentage,
                  total_percentage: total_percentage,
                  incentives: ince,
                  duration: years + ' years ' + months + ' months ' + days + ' days',
                })
                if (ss_head) {
                  if ((Object.keys(item.evaluation).length === 0 && item.is_head) || (Object.keys(item.bod_evaluation).length === 0 && !item.is_head)) {
                    can_final = false;
                  }
                } else {
                  if (Object.keys(item.evaluation).length === 0 && item.created_by != 2) {
                    can_final = false;
                  }
                }
              })
              if (can_final) {
                this.create_employee_is_done_evaluate(data)
              }
              this.final_department_personal_info = temp
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
      this.list_of_employee_to_evaluate(data)
        .then(response => {
          if (response.data[0].details === 'generated') {
            this.is_generated = true
            this.bank_items = response.data[0].data
          } else {
            this.personal_info = response.data[0].data
            if (this.personal_info.length > 0) {
              var can_final = true
              var temp = []
              var over_all_points_rawr = 0
              var head_over_all_points_rawr = 0

              var ss_head = this.is_head;
              this.personal_info.forEach(function (item) {
                over_all_points_rawr = item.subordinate_evaluation.length * total_points_each_employee
                head_over_all_points_rawr = item.head_evaluation.length * total_points_each_employee
                var total_subordinate = 0
                item.subordinate_evaluation.forEach(function (item) {
                  if (item.is_csr_and_gad) {
                    total_subordinate += (parseFloat(item.total) - (parseFloat(item.q26) + parseFloat(item.q27) + parseFloat(item.q28) + parseFloat(item.q29) + parseFloat(item.q30)))
                  } else {
                    total_subordinate += parseFloat(item.total)
                  }
                })
                var total_head = 0
                item.head_evaluation.forEach(function (item) {
                  total_head += parseFloat(item.total)
                })
                var total_csr_gad = 0
                item.csr_and_gad_evaluation.forEach(function (item) {
                  if (item.is_subordinate) {
                    total_csr_gad += (parseFloat(item.q26) + parseFloat(item.q27) + parseFloat(item.q28) + parseFloat(item.q29) + parseFloat(item.q30))
                  } else {
                    total_csr_gad += parseFloat(item.total)
                  }
                })
                var employee_percentage = item.subordinate_evaluation.length > 0 ? (((total_subordinate.toFixed(2)
                  / (over_all_points_rawr)) * 40).toFixed(2)) : 0;
                var head_percentage = item.head_evaluation.length > 0 ?
                  (((parseFloat(total_head.toFixed(2)) / (head_over_all_points_rawr)) * 20).toFixed(2)) : 0;
                var csr_and_gad_percentage = item.csr_and_gad_evaluation.length > 0 ?
                  (((parseFloat(total_csr_gad.toFixed(2)) / (total_gad_points_each_employee)) * 20).toFixed(2)) : 0;
                var bod_points = item.bod_evaluation.length > 0 ? item.bod_evaluation[0].total : 0;
                var bod_percentage = item.bod_evaluation.length > 0 ? ((parseFloat(bod_points.toFixed(2)) / total_points_each_employee) * 20).toFixed(2) : 0;

                var total_percentage = (parseFloat(employee_percentage) + parseFloat(head_percentage) + parseFloat(csr_and_gad_percentage) + parseFloat(bod_percentage)).toFixed();
                var ince = 0
                if (total_percentage >= 96 && total_percentage <= 100) {
                  ince = 5000;
                } else if (total_percentage >= 91 && total_percentage <= 95) {
                  ince = 4000;
                } else if (total_percentage >= 86 && total_percentage <= 90) {
                  ince = 3500;
                } else if (total_percentage >= 81 && total_percentage <= 85) {
                  ince = 3000;
                } else if (total_percentage >= 76 && total_percentage <= 80) {
                  ince = 2000;
                } else if (total_percentage >= 71 && total_percentage <= 75) {
                  ince = 1500;
                } else if (total_percentage <= 70) {
                  ince = 0;
                }
                if (item.id === 188 || item.has_done_evaluate.length === 0) {//Laingo, Harold
                  ince = 0
                }
                var a = moment();
                var b = moment(item.date_of_employment, 'YYYY-MM-DD');

                var years = a.diff(b, 'year');
                b.add(years, 'years');

                var months = a.diff(b, 'months');
                b.add(months, 'months');

                var days = a.diff(b, 'days');

                temp.push({
                  id: item.id,
                  is_head: item.is_head,
                  has_done_evaluate: item.has_done_evaluate,
                  name: item.last_name + ','
                    + item.first_name + ' ' + item.middle_name,
                  branch: item.branch,
                  bank_type: item.bank_type_1,
                  bank_account_name: item.bank_account_1,
                  bank_account_no: item.bank_account_no_1,
                  employee_position: item.employee_position,
                  date_of_employment: item.date_of_employment,
                  over_all_evaluation: item.over_all_evaluation,
                  branch_id: item.branch_id,
                  evaluation_points: item.evaluation.length > 0 ? item.evaluation[0].total : 0,
                  status: Object.keys(item.evaluation).length > 0 ? 1 : 0,
                  subordinates_points: total_subordinate,
                  employee_percentage: employee_percentage,
                  csr_and_gad_points: total_csr_gad,
                  csr_and_gad_percentage: csr_and_gad_percentage,
                  head_points: total_head,
                  head_percentage: head_percentage,
                  bod_points: bod_points,
                  bod_percentage: bod_percentage,
                  total_percentage: total_percentage,
                  incentives: ince,
                  duration: years + ' years ' + months + ' months ' + days + ' days',
                })
                if (ss_head) {
                  if ((Object.keys(item.evaluation).length === 0 && item.is_head) || (Object.keys(item.bod_evaluation).length === 0 && !item.is_head)) {
                    can_final = false;
                  }
                } else {
                  if (Object.keys(item.evaluation).length === 0 && item.created_by != 2) {
                    can_final = false;
                  }
                }
              })
              if (can_final) {
                this.create_employee_is_done_evaluate(data)
              }
              this.final_personal_info = temp
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
      this.saving = false
    },
    approved_data() {
      this.saving_data = true
      this.alert = false

      if (this.personal_info.length > 0) {
        var can_final = true
        // this.personal_info.forEach(function (item) {
        //   if (Object.keys(item.has_done_evaluate).length === 0) {
        //     can_final = false;
        //   }
        // })
        if (can_final) {
          const data = new FormData()
          data.append('month_of_id', this.month_of_id);
          data.append('category_id', this.category_id);
          data.append('evaluation_data', JSON.stringify(this.final_personal_info));
          data.append('evaluation_data_department', JSON.stringify(this.final_department_personal_info));
          this.create_employee_evaluation_data(data)
            .then(() => {
              this.saving_data = false
              this.selected_data()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Not all employee evaluated'
          this.saving_data = false
        }
      } else {
        this.alert = true
        this.alert_message = 'No Data'
        this.saving_data = false
      }
    },
    print_data() {
      this.is_printing = true
      this.alert = false

      var imgData = this.company_logo
      var payments_array = []
      var payments_array2 = []
      var widths = []
      var info_array = []
      var info_array2 = []
      widths = [30, 150, 100, 80, 80, 60]
      payments_array.push([
        {text: 'No.', alignment: 'left', style: 'main_info',},
        {text: 'Name', alignment: 'left', style: 'main_info'},
        {text: 'Branch/Department', alignment: 'left', style: 'main_info'},
        {text: 'Designation', alignment: 'left', style: 'main_info'},
        {text: 'Duration', alignment: 'left', style: 'main_info'},
        {text: 'Incentives', alignment: 'left', style: 'main_info'},
      ])
      payments_array2.push([
        {text: 'No.', alignment: 'left', style: 'main_info',},
        {text: 'Name', alignment: 'left', style: 'main_info'},
        {text: 'Branch/Department', alignment: 'left', style: 'main_info'},
        {text: 'Designation', alignment: 'left', style: 'main_info'},
        {text: 'Duration', alignment: 'left', style: 'main_info'},
        {text: 'Incentives', alignment: 'left', style: 'main_info'},
      ])
      var value = this.final_personal_info;
      var value2 = this.final_department_personal_info;
      var total_am = 0
      value.forEach(function (item) {
        total_am += parseFloat(item.incentives)
        payments_array.push([
          {
            text: (value
              .map(function (x) {
                return x.id
              })
              .indexOf(item.id) + 1),
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: item.employee.last_name + ', ' +
              item.employee.first_name + ' ' + item.employee.middle_name,
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: item.branch.branch_code,
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: item.employee_position.position,
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: item.duration,
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: (item.incentives / 1)
              .toFixed(2)
              .replace(',', '.')
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            alignment: 'left',
            style: 'tableExample2'
          },
        ])
      })
      value2.forEach(function (item) {
        total_am += parseFloat(item.incentives)
        payments_array2.push([
          {
            text: (value2
              .map(function (x) {
                return x.id
              })
              .indexOf(item.id) + 1),
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: item.employee.last_name + ', ' +
              item.employee.first_name + ' ' + item.employee.middle_name,
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: item.branch.branch_code,
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: item.employee_position.position,
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: item.duration,
            alignment: 'left',
            style: 'tableExample2'
          },
          {
            text: (item.incentives / 1)
              .toFixed(2)
              .replace(',', '.')
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            alignment: 'left',
            style: 'tableExample2'
          },
        ])
      })

      info_array = [
        {
          columns: [
            {
              style: 'main_info',
              text: [
                'Bank Type: ',
                {
                  text: this.bank_items[this.bank_items.map(function (x) {
                    return x.bank_type;
                  }).indexOf(this.bank)].bank_type,
                  style: 'main_data',
                },
              ],
            },
          ],
        }, {
          columns: [
            {
              style: 'main_info',
              text: [
                'Category: ',
                {
                  text: this.category_items[this.category_items.map(function (x) {
                    return x.id;
                  }).indexOf(this.category_id)].category,
                  style: 'main_data',
                },
              ],
            },
          ],
        },
        {
          columns: [
            {
              style: 'main_info',
              text: [
                {
                  text: 'Month Of: ' + this.month_of_items[this.month_of_items.map(function (x) {
                    return x.id;
                  }).indexOf(this.month_of_id)].month_of,
                  style: 'main_data',
                },
              ],
            },

          ],
        }, {
          columns: [
            {
              style: 'main_info',
              text: [
                {
                  text: 'Total Of: ' + (total_am / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  style: 'main_data',
                },
              ],
            },

          ],
        },
        {
          columns: [
            {
              style: 'main_info',
              text: [
                'HEADS',
              ],
            },
          ],
        },
      ]
      info_array2 = [
        {
          columns: [
            {
              style: 'main_info',
              text: [
                'DEPARTMENTS',
              ],
            },
          ],
        },
      ]

      //call pdfmake
      var pdfMake = require('pdfmake/build/pdfmake.js')
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require('pdfmake/build/vfs_fonts.js')
        pdfMake.vfs = pdfFonts.pdfMake.vfs
      }
      var docDefinition = {
        pageSize: 'LETTER',
        pageOrientation: 'portrait',
        content: [
          {
            columns: [
              {image: imgData, width: 54, height: 54, style: 'logo'},
              {
                stack: [
                  'GOODLIFE GROUP OF COMPANIES',
                  {text: this.address, style: 'subheader'},
                  {
                    text: this.contact,
                    style: 'subheader',
                  },
                  {
                    text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                    style: 'printed_label',
                  },
                ],
                style: 'header',
              },
            ],
          },
          '_______________________________________________________________________________________________',
          {
            stack: [
              {
                columns: [
                  {
                    text: 'EMPLOYEE INCENTIVES',
                    style: {
                      fontSize: 16,
                      alignment: 'center',
                      bold: true,
                      color: 'blue',
                    },
                  },
                ],
              },
            ],
          },
          {
            columns: [{text: ' '}],
          },
          {
            columns: [{text: ' '}],
          },
          {
            stack: info_array,
          },
          {
            columns: [{text: ' '}],
          },
          {
            table: {
              widths: widths,
              body: payments_array,
              style: {fontSize: 1},
            },
          },
          '_______________________________________________________________________________________________',
          {
            stack: info_array2,
          },
          {
            table: {
              widths: widths,
              body: payments_array2,
              style: {fontSize: 1},
            },
          },
          {
            stack: [
              {
                columns: [{text: ' '}],
              },
              {
                columns: [{text: ' '}],
              },
              {
                columns: [
                  {
                    text: 'PROCESSED BY',
                    style: 'main_data_2',
                  },
                  {
                    text: 'DEPARTMENT RECEIVER',
                    style: 'main_data_2',
                  },
                  {
                    text: 'RECEIVED BY',
                    style: 'main_data_2',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: this.name,
                    style: 'main_data_2',
                  },
                  {
                    text: ' ',
                  },
                  {
                    text: '',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: '_____________________',
                  },
                  {
                    text: '_____________________',
                  },
                  {
                    text: '_____________________',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: this.position,
                    style: 'main_data_2',
                  },
                  {
                    text: 'Signature Over Printed Name',
                    style: 'main_data_2',
                  },
                  {
                    text: 'Signature Over Printed Name',
                    style: 'main_data_2',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: 'VERIFIED BY',
                    style: 'main_data_2',
                  },
                  {
                    text: '',
                  },
                  {
                    text: '',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: '_________________________________________',
                  },
                  {
                    text: ' ',
                  },
                  {
                    text: ' ',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: 'BOARD OF DIRECTOR',
                    style: 'main_data_2',
                  },
                  {
                    text: '',
                  },
                  {
                    text: '',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {image: this.damayan, width: 54, height: 40, style: 'logo'},
              {image: this.chapel, width: 54, height: 40, style: 'logo'},
              {image: this.factory, width: 54, height: 40, style: 'logo'},
              {image: this.coop, width: 54, height: 40, style: 'logo'},
              {image: this.printing, width: 54, height: 40, style: 'logo'},
            ],
          },
        ],
        footer: {
          // columns: [
          //     {
          //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
          //         alignment: 'center',
          //         style: 'tableExample'
          //     }
          // ]
        },
        styles: {
          tableExample: {
            fontSize: 9,
          },
          tableExample2: {
            fontSize: 7,
          },
          header: {
            fontSize: 21,
            bold: true,
            alignment: 'left',
            margin: [6, 6, 0, 5], //[left, top, right, bottom]
          },
          subheader: {
            fontSize: 11,
          },
          logo: {
            alignment: 'center',
            margin: [0, 0, 0, 0], //[left, top, right, bottom]
          },
          printed_label: {
            alignment: 'right',
            fontSize: 9,
            margin: [0, 0, 0, 0], //[left, top, right, bottom]
          },
          main_data: {
            margin: [0, 2, 0, 2],
            fontSize: 11,
            bold: true,
            alignment: 'left',
          },
          main_data_2: {
            margin: [0, 2, 0, 2],
            fontSize: 8,
            bold: true,
            alignment: 'left',
          },
          main_info: {
            margin: [0, 2, 0, 2],
            fontSize: 10,
            alignment: 'left',
          },
        },
      }
      pdfMake.createPdf(docDefinition).open()
      this.is_printing = false
    }
  },
}
</script>
